import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ThankYouForBooking.scss";

import { Layout, Space, Typography, Divider, Tooltip } from "antd";
import Accordion, { Panel } from "components/Accordion/Accordion";
import HorizontalSlide from "components/HorizontalSlide/HorizontalSlide";
import { openNotificationWithIcon } from "utils/Notification";

import IntakeForms from "containers/Account/Screens/IntakeForms/IntakeForms";
import BookingPayment from "./components/BookingPayment";
import OrderSummary from "./components/OrderSummary";

import { Button } from "components/Buttons/Buttons";
import { CalendarOutlined, VideoCameraOutlined } from "@ant-design/icons";

import ProductCard from "components/ProductCard/ProductCard";
import InviteFriendsCard from "./components/InviteFriendsCard";
import RegisterModal from "./components/RegisterModal";

import { push } from "connected-react-router";
import moment from "moment";
import Glutathione from "assets/images/Glutathione.png";
import Modal from "components/Modal/Modal";
import {
  createBooking,
  getOrderById,
  createAddOn,
  deleteLineItem,
  addLineItme,
} from "services/booking.service";
import {
  getAddons,
  getProducts,
  getStateAndCountryFromZip,
} from "services/locationCenter.services";
import { getItem, setItem } from "utils/storage";
import { ReactComponent as CalenderIcon } from "assets/icons/calenderIco.svg";
import { setLoading } from "redux/actions/loading.actions";
import { findWindows } from "windows-iana";

import AddToCalendar from "components/AddToCalendar/AddToCalendar";
import StripeAddCardModal from "components/StripeAddCard/StripeAddCardModal";
import { clearTimer } from "services/timer.service";
import { addonsTextReducer, getRestrictionValues } from "utils/common";

import ContactImage from "assets/images/error.jpg";
import RetryImage from "assets/images/retry_booking.png";
import useIntakeForms from "hooks/useIntakeForms";

import { MeetingVendor, OperationType } from "utils/enums";
import { getRewards } from "services/rewards.service";
import { getCustomerWallet } from "services/auth.services";
import { validateRestriction } from "services/validate.services";
import { MEET_PROVIDER } from "utils/constants";

// const { Panel } = Collapse;
const { Content } = Layout;
const { Text, Title, Link } = Typography;

export default function ThankyouForBooking() {
  const [addOns, setAddons] = useState([]);
  const [panel, setPanel] = useState("0"); // "1"
  const [serviceModal, setServiceModal] = useState(false);
  const [completed, setCompleted] = useState([""]); // no value
  const [currentLineItem, setCurrentLineItem] = useState(null); // no value
  const addToCalenderRef = useRef();
  // const [product, setProduct] = useState(order?.lineItems?.[0]?.product);
  const [showIntakeForms, setShowIntakeForms] = useState(false);
  const [termsAndConditions, setTermsAndCondition] = useState("");
  const [goodFaithExamPolicy, setGoodFaithExamPolicy] = useState("");
  const { miscSettings } = useSelector((state) => ({
    miscSettings: state.misc.miscSettings,
  }));
  useEffect(() => {
    if (miscSettings) {
      const TAndC = miscSettings.find((res) => {
        if (res.key == "TAndC") {
          return res;
        }
      });
      const goodFaithExamPolicy = miscSettings.find((res) => {
        if (res.key == "GoodFaithExamPolicy") {
          return res;
        }
      });
      setTermsAndCondition(TAndC?.value || "");
      setGoodFaithExamPolicy(goodFaithExamPolicy?.value || "");
    }
  }, [miscSettings]);
  const dispatch = useDispatch();
  const {
    user,
    booking: order,
    paymentMethod: { paymentMethods },
    intakeForms,
  } = useSelector((state) => ({
    user: state.user,
    booking: state.booking.currentBooking,
    paymentMethod: state.paymentMethod,
    intakeForms: state.intakeForms,
  }));
  const { products } = useSelector((state) => state.locationCenter);
  const [categoryId, setCategoryId] = useState(undefined);
  const [showAddCard, setShowAddCard] = useState(false);

  const [showUserEditModal, setShowUserEditModal] = useState(false);

  const [errorRetry, setErrorRetry] = useState({
    retryOnce: false,
    retryTwice: false,
  });

  const {
    email,
    firstName,
    lastName,
    city,
    state,
    zipCode,
    streetAddress1,
    dob,
  } = user.currentUser ?? {};

  const profiles = user.profiles;
  const { consent, physicalHistory } = intakeForms;
  // TODO: AFTER BACKEND CODE FIX THIS
  const {
    isHistoryFormSubmitted,
    isConsentFormSubmitted,
    isHistoryFormLegacy,
    isConsentFormLegacy,
    isPhysicalHistoryExpired,
    isConsentExpired,
  } = useIntakeForms();
  const intakeFormsSubmitted =
    (isHistoryFormSubmitted || isHistoryFormLegacy) &&
    (isConsentFormSubmitted || isConsentFormLegacy);
  const intakeFormsExpired = isPhysicalHistoryExpired || isConsentExpired;

  const getData = useCallback(
    async (paymentAdded) => {
      dispatch(setLoading(true));
      const pendingOrder = getItem("pendingOrder");

      if (!pendingOrder?.isBookingClicked && !Boolean(order?.orderId)) {
        dispatch(setLoading(false));
        dispatch(push("/"));
      } else if (
        pendingOrder?.status === "pending" &&
        order.orderId === undefined
      ) {
        let dobMoment = moment(dob);

        if (
          !(
            dobMoment.isSameOrBefore(moment().subtract(18, "years")) &&
            dobMoment.isAfter(moment().subtract(85, "years"))
          )
        ) {
          openNotificationWithIcon(
            "error",
            "Error!",
            "Unable to book order. Your age must be between 18 and 85. Kindly consult support for more details."
          );
          dispatch(setLoading(false));
          return;
        }

        if (!intakeFormsSubmitted || intakeFormsExpired) {
          // openNotificationWithIcon(
          //   "error",
          //   "Error!",
          //   "Unable to book order. You must fill the intake forms."
          // );
          setShowIntakeForms(true);
          dispatch(setLoading(false));
          return;
        }
        let isMobile = order?.operationTypeId === 1 ? true : false;
        let isTelehealth = order?.operationTypeId === 1 ? true : false;

        if (paymentMethods?.length < 1 && !paymentAdded) {
          setShowAddCard(true);
          dispatch(setLoading(false));
          return;
        }
        // const newOrder = { ...order };
        const windowsTimezone = findWindows(moment?.tz?.guess?.() || "");

        const primaryPaymentMethod =
          paymentMethods.find((card) => card?.isPrimary === true) ??
          paymentMethods[0];
        order.paymentMethodId =
          primaryPaymentMethod?.paymentMethodId ?? paymentAdded;

        let stateFromGoogle = undefined;
        let countryFromGoogle = undefined;
        let zipCodeFromGoogle = undefined;
        if (order.operationTypeId !== OperationType.Clinic) {
          dispatch(setLoading(true));
          let address =
            order.operationTypeId === OperationType.Mobile
              ? order.serviceAddress
              : `USA ${order.zipCode}`;
          const { state, country, zipCode } =
            (await getStateAndCountryFromZip(address)) ?? {};

          if (state && country && zipCode) {
            stateFromGoogle = state || "";
            countryFromGoogle = country || "";
            zipCodeFromGoogle = zipCode || "";
          } else {
            dispatch(setLoading(false));
            openNotificationWithIcon(
              "error",
              "Invalid Address/ZIP Code",
              "Unable to find the address. Please go back and enter a valid street address."
            );
            return;
          }
        }

        const dateFormatted = moment(order?.serviceDate)?.format();
        const valuesToValidate = getRestrictionValues(
          order,
          dateFormatted,
          user?.customerId
        );

        const restrictionsRes = await dispatch(
          validateRestriction(
            order?.facilityId,
            valuesToValidate,
            dateFormatted
          )
        );

        if (!restrictionsRes?.mstatus) {
          const retryOnce = errorRetry.retryOnce;
          setErrorRetry({
            retryOnce: true,
            retryTwice: retryOnce ? true : false,
          });
          return;
        }

        dispatch(
          createBooking(
            {
              ...order,
              stateName: stateFromGoogle,
              countryCode: countryFromGoogle,
              zipCode: zipCodeFromGoogle,
              timezone: windowsTimezone?.[0],
            },
            user.currentUser
          )
        ).then((res) => {
          if (res?.status !== "success") {
            const retryOnce = errorRetry.retryOnce;
            setErrorRetry({
              retryOnce: true,
              retryTwice: retryOnce ? true : false,
            });
            if (retryOnce) {
              dispatch(clearTimer());
            }
          } else {
            setErrorRetry({
              retryOnce: false,
              retryTwice: false,
            });
          }
        });
        dispatch(
          getAddons(
            order?.facilityId,
            order?.operationTypeId,
            order?.lineItems?.[0]?.productId
          )
        ).then((addon) => setAddons(addon));
        // dispatch(updatePendingOrder(newOrder));
      } else {
        const newOrder = await dispatch(
          getOrderById(order?.orderId, order?.isMultipleGuestBooking)
        );
        await dispatch(
          getAddons(
            newOrder?.facilityId,
            newOrder?.operationTypeId,
            newOrder?.lineItems?.[0]?.productId
          )
        ).then((addon) => setAddons(addon));

        dispatch(setLoading(false));
        // setItem("pendingOrder", order);
        // fetch order details using orderID
      }
    },
    [paymentMethods, order, user, intakeForms, errorRetry]
  );

  useEffect(() => {
    dispatch(getCustomerWallet());
  }, []);

  useEffect(() => {
    getData();
    // using local storage because if thankyou is directly accessed it will be empty in redux
  }, [intakeFormsSubmitted]);

  useEffect(async () => {
    if (order?.facilityId && order?.operationTypeId)
      await dispatch(getProducts(order?.facilityId, order?.operationTypeId));
  }, []);

  const orderId = order?.orderId;

  const [dateScheduled, startDate, endDate] = useMemo(() => {
    const dateScheduled = moment(order?.serviceDate).format(
      "MMMM DD, yyyy - h:mmA"
    );
    const startDate = moment(order?.serviceDate);
    const endDate = moment(startDate).add(1, "hours");
    return [dateScheduled, startDate, endDate];
  }, [order?.serviceDateUTC]);

  const removeAddon = useCallback(
    async (id, pId) => {
      let newOrder = { ...order };
      newOrder.lineItems = newOrder?.lineItems?.map((item) => {
        if (item.lineItemId === pId) {
          item.addOns = item?.addOns?.filter((adon) => adon.lineItemId !== id);
        }
        return item;
      });
      dispatch(setLoading(true));
      await dispatch(deleteLineItem(orderId, id, newOrder));
      await dispatch(getRewards());
      dispatch(setLoading(false));
    },
    [order]
  );

  const addBoosts = useCallback(
    async (id) => {
      dispatch(setLoading(true));
      const lineItemId = order?.lineItems[0].lineItemId;
      await dispatch(createAddOn([id], orderId, lineItemId));
      await dispatch(getRewards());
      getData();
    },
    [orderId, order]
  );

  const categories = useMemo(() => {
    const categorySet = new Set(
      products.map((product) => product.productCategoryId)
    );
    const categoriesObject = [...categorySet].map((categoryId) => {
      const category = products.find(
        (product) => product.productCategoryId === categoryId
      );
      return { name: category.productCategoryName, id: categoryId };
    });

    return [{ name: "All", value: null }, ...categoriesObject];
  }, [products]);

  const orderAddons = useMemo(() => {
    const addOns = [];
    for (let i = 0; i < order?.lineItems?.length; i++) {
      if (order?.lineItems?.[i]?.addOns?.length) {
        for (let j = 0; j < order?.lineItems?.[i]?.addOns?.length; j++) {
          addOns.push(order?.lineItems?.[i]?.addOns?.[j]?.productId);
        }
      }
    }
    return addOns;
  }, [order]);

  const [
    isTriImmuneSelected,
    isGlutathioneSelected,
    isZincSelected,
    isB12Selected,
    isSkinnySelected,
  ] = useMemo(() => {
    let isTriImmuneSelected = false;
    let isGlutathioneSelected = false;
    let isZincSelected = false;
    let isB12Selected = false;
    let isSkinnySelected = false;

    orderAddons.forEach((addOnId) => {
      const addOn = addOns.find((addon) => addOnId === addon.productId);
      if (addOn?.productName === "Tri-Immune") isTriImmuneSelected = true;
      if (addOn?.productName === "Glutathione") isGlutathioneSelected = true;
      if (addOn?.productName === "Zinc") isZincSelected = true;
      if (addOn?.productName === "B-12") isB12Selected = true;
      if (addOn?.productName === "Skinny") isSkinnySelected = true;
    });
    return [
      isTriImmuneSelected,
      isGlutathioneSelected,
      isZincSelected,
      isB12Selected,
      isSkinnySelected,
    ];
  }, [orderAddons, addOns]);

  const checkIfAddonIsDisabled = (addOn) => {
    if (addOn?.productName === "Tri-Immune" && isGlutathioneSelected)
      return true;
    if (addOn?.productName === "Glutathione" && isTriImmuneSelected)
      return true;
    if (addOn?.productName === "Tri-Immune" && isZincSelected) return true;
    if (addOn?.productName === "Zinc" && isTriImmuneSelected) return true;
    if (addOn?.productName === "B-12" && isSkinnySelected) return true;
    if (addOn?.productName === "Skinny" && isB12Selected) return true;
    return false;
  };
  const onServiceChangeSingle = async (value) => {
    const data = {
      productId: value,
      userProfileId: 0,
      firstName: user.currentUser.firstName,
      lastName: user.currentUser.lastName,
      email: user.currentUser.email,
      addOns: [],
    };
    dispatch(setLoading(true));
    setServiceModal(false);
    await dispatch(deleteLineItem(orderId, currentLineItem));
    await dispatch(addLineItme(orderId, [data]));
    const addOn = await dispatch(
      getAddons(order?.facilityId, order?.operationTypeId, value)
    );
    setAddons(addOn);
    getData();
  };

  const categoryProducts = useMemo(() => {
    const categoryProducts = products.filter((product) => {
      if (categoryId) return product.productCategoryId === categoryId;
      else return true;
    });

    return categoryProducts;
  }, [products, categoryId]);

  // render variables start
  const product = products?.find(
    (prod) => order?.lineItems?.[0]?.productId == prod.productId
  );
  const selectedAddons = useMemo(() => {
    if (addOns?.length === 0 && order?.lineItems?.[0]?.addOns?.length > 0) {
      return order?.lineItems?.[0]?.addOns;
    }
    return addOns?.filter?.((addon) =>
      order.lineItems[0].addOns
        .map((lineItem) => lineItem.productId)
        .includes(addon.productId)
    );
  }, [order, addOns]);

  if (errorRetry.retryTwice) {
    return (
      <Content className="thankyouContainer">
        <div className="errorContainer">
          <Text style={{ fontWeight: 600, marginBottom: 60, fontSize: 20 }}>
            Oops. Please call <a href="tel:+18559547843">(855) 954-7843</a> to
            complete booking. We apologize for the inconvenience!
          </Text>
          <img
            src={ContactImage}
            alt="Error"
            style={{
              height: 160,
              width: 160,
              objectFit: "cover",
            }}
          />
        </div>
      </Content>
    );
  }

  if (errorRetry.retryOnce) {
    return (
      <Content className="thankyouContainer">
        <div className="errorContainer">
          <Text style={{ fontWeight: 600, marginBottom: 46, fontSize: 20 }}>
            Sorry, something went wrong. Please click{" "}
            <span
              onClick={() => getData()}
              style={{
                color: "#486baf",
                cursor: "pointer",
              }}
            >
              Retry
            </span>
            .
          </Text>
          <img
            src={RetryImage}
            alt="Retry Request"
            style={{
              height: 140,
              width: 140,
              objectFit: "cover",
            }}
          />
          {/* <Button rounded={true} onClick={() => getData()}>
            Retry
          </Button> */}
        </div>
      </Content>
    );
  }

  // render variables end
  return (
    <Content className="thankyouContainer">
      <Modal
        visible={serviceModal}
        onCancel={() => setServiceModal(false)}
        width={1000}
        className="serviceModal"
        title="Change Services"
        footer={null}
      >
        <div
          onClick={(e) => e.preventDefault()}
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: 900,
            height: 400,
            background: "#fff",
            zIndex: 100,
          }}
        >
          <div
            style={{
              borderRight: "2px solid #eee",
              flex: 2,
              padding: 16,
            }}
          >
            {categories?.map?.((category) => (
              <div
                key={category.id ?? "na"}
                className={`servicesCategory ${
                  categoryId === category.id ? "selected" : ""
                }`}
                onClick={() => {
                  setCategoryId(category.id);
                }}
              >
                {category.name}
              </div>
            ))}
          </div>
          <div style={{ flex: 3, padding: 16 }}>
            {categoryProducts?.map?.((product) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 20,
                    cursor: "pointer",
                  }}
                  key={product.productId}
                  onClick={() => onServiceChangeSingle(product.productId)}
                >
                  <div style={{ display: "flex" }}>
                    <img
                      src={Glutathione}
                      width={68}
                      height={56}
                      alt="product icon"
                      style={{ borderRadius: 10 }}
                    />
                    <div style={{ marginLeft: 12 }}>
                      <p
                        style={{
                          margin: 0,
                          fontSize: 20,
                          fontWeight: "bold",
                          color: "#486BAF",
                          fontFamily: "hendersonBasic",
                        }}
                      >
                        {product.productName}
                      </p>
                      <p
                        style={{
                          margin: 0,
                          fontFamily: "hendersonBasic",
                          fontSize: 16,
                          fontWeight: "bold",
                        }}
                      >
                        ${product.sellingPrice}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
      <Space direction="vertical" className="thankYouWrapper">
        <Title level={3}>
          Welcome, <span className="thankyouName">{firstName}</span>!
        </Title>
        <Title level={1}>Thank you for Booking!</Title>
        <div className="flex justifySpaceBetween alignCenter">
          <Title
            style={{ color: "#486BAF", lineHeight: 1, marginBottom: 0 }}
            level={4}
          >
            {product?.productName}
            {addOns?.length > 0 &&
              selectedAddons?.length > 0 &&
              ` with ${selectedAddons.reduce(addonsTextReducer, "")} `}{" "}
          </Title>

          <AddToCalendar
            startDate={startDate?.format?.("YYYY-MM-DD")}
            startTime={startDate?.format?.("HH:mm")}
            endDate={endDate?.format?.("YYYY-MM-DD")}
            endTime={endDate?.format?.("HH:mm")}
            name="Liquid Mobile Appointment"
            description={
              order?.joinUrl
                ? `You have a telehealth appointment scheduled with the Liquid Mobile team.  <a href="${order?.joinUrl}"> Meeting URL <a>.`
                : "You have an appointment scheduled with the Liquid Mobile team."
            }
            location={order?.joinUrl ? order?.joinUrl : order?.serviceAddress}
            timeZone={order?.timeZone}
          >
            <Button rounded icon={<CalendarOutlined />}>
              Add to Calendar
            </Button>
          </AddToCalendar>
        </div>

        <Space className="thankyouBookingDate">
          <CalenderIcon />
          <Title
            className="tradeGothic"
            level={4}
            style={{ margin: 0, lineHeight: "22px" }}
          >
            {dateScheduled} {!!order?.timeZone && <>({order?.timeZone})</>}
          </Title>
        </Space>
        <Accordion
          className="thankyouAccordion"
          panel={panel}
          onChange={(key) => {}}
          completed={completed}
        >
          <Panel
            key={1}
            title={
              order?.operationTypeId === 3
                ? `${
                    order?.meetingVendor !== MeetingVendor.Zoom
                      ? MEET_PROVIDER
                      : "Zoom"
                  } Meeting`
                : "Service Location"
            }
            isCompleted={true}
            completedMeta={
              <div className="flex  justifySpaceBetween">
                <Space
                  direction="vertical"
                  size={0}
                  className="thankyouBasicInfo"
                >
                  <p
                    style={{
                      width: "70%",
                      fontFamily: "tradeGothic",
                      margin: 0,
                      fontSize: 16,
                    }}
                  >
                    {order?.operationTypeId === 3 && order?.joinUrl ? (
                      <Space
                        onClick={() => {
                          if (order?.joinUrl)
                            window.open(order?.joinUrl, "_blank");
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <VideoCameraOutlined
                          style={{ fontSize: 17, color: "#486baf" }}
                        />
                        <Text
                          style={{
                            color: "#486baf",
                            display: "inline-block",
                            marginTop: -5,
                            lineHeight: 1.5,
                            fontSize: 17,
                            fontFamily: "tradeGothic",
                          }}
                        >
                          Join{" "}
                          {order?.meetingVendor !== MeetingVendor.Zoom
                            ? MEET_PROVIDER
                            : "Zoom"}{" "}
                          Call
                        </Text>
                      </Space>
                    ) : (
                      order?.serviceAddress && (
                        <>
                          {`${order?.serviceAddress} (${
                            order?.operationTypeId === 1 ? "Mobile" : "Clinic"
                          } Service)`}
                        </>
                      )
                    )}
                  </p>
                </Space>
                {/* <Link underline onClick={() => setShowUserEditModal(true)}>
                  Change
                </Link> */}
                <RegisterModal
                  visible={showUserEditModal}
                  onClose={() => setShowUserEditModal(false)}
                />
              </div>
            }
            isClickable={false}
          >
            &nbsp;
          </Panel>
          <Panel
            key={2}
            title="Intake Forms "
            isCompleted={true}
            completedMeta={<IntakeForms variant="minimal" />}
            isClickable={false}
          >
            &nbsp;
          </Panel>
          <Panel
            key={3}
            title="Payments"
            isCompleted={true}
            completedMeta={
              <BookingPayment order={order} paymentMethods={paymentMethods} />
            }
            isClickable={false}
          >
            &nbsp;
          </Panel>

          <Panel
            key={4}
            title="Summary "
            isCompleted={true}
            completedMeta={
              <OrderSummary
                isMultipleGuestBooking={order?.isMultipleGuestBooking}
                order={order}
                getData={getData}
                removeAddon={removeAddon}
                setServiceModal={(id) => {
                  setServiceModal(true);
                  setCurrentLineItem(id);
                }}
              />
            }
            isClickable={false}
            className="thankyouSummary"
          >
            &nbsp;
          </Panel>
        </Accordion>
        {addOns?.length ? (
          <HorizontalSlide
            className="thankyouSlide"
            title="Customers who bought this item also bought"
          >
            {addOns
              ?.filter?.(
                (item) =>
                  !orderAddons.includes(item.productId) &&
                  !checkIfAddonIsDisabled(item)
              )
              ?.map((item) => {
                return (
                  <ProductCard
                    disabled={
                      order?.orderId === undefined ||
                      order?.orderStatus === "Cancel" ||
                      order?.orderStatus === "Completed"
                    }
                    key={item.productId}
                    addBoosts={addBoosts}
                    title={item.productName}
                    action={{
                      text: "Add to Cart",
                      onClick: () => {
                        addBoosts(item.productId);
                      },
                    }}
                  />
                );
              })}
          </HorizontalSlide>
        ) : null}
        <div className="thakyouCancelNotice">
          <Space
            size="small"
            split={
              <Divider
                type="vertical"
                style={{ backgroundColor: "#6C798F", margin: 0 }}
              />
            }
          >
            <Text style={{ color: "#6C798F", fontSize: 14 }}>
              To cancel an appointment, visit Upcoming Appointments under My
              Accounts
            </Text>
            <Tooltip
              overlayStyle={{ maxWidth: 640 }}
              placement="bottom"
              title={termsAndConditions}
            >
              <Link underline style={{ fontSize: 14 }}>
                Cancellation Policy
              </Link>
            </Tooltip>
            <Tooltip
              overlayStyle={{ maxWidth: 640 }}
              placement="bottom"
              title={goodFaithExamPolicy}
            >
              <Link underline style={{ fontSize: 14 }}>
                Good Faith Exam Fee
              </Link>
            </Tooltip>
          </Space>
        </div>

        {/* Invite your friends and Earn Link Start */}
        {/* <InviteFriendsCard /> */}

        {/* Invite your friends and Earn Link End */}
      </Space>
      <Modal
        className="intakeFormsPopup"
        title="Intake Forms"
        headerBorder={true}
        visible={showIntakeForms}
        onCancel={() => setShowIntakeForms(false)}
        okButtonProps={{
          disabled: !intakeFormsSubmitted,
        }}
        onOk={() => {
          setShowIntakeForms(false);
          getData();
        }}

        // footer={[]}
      >
        <p style={{ fontFamily: "tradeGothic", fontSize: 17 }}>
          Intake forms are mandatory to book an appointment.
        </p>
        <IntakeForms variant="minimal" />
      </Modal>
      <StripeAddCardModal
        message="Payment method is mandatory for booking a service"
        saveText={"Save and Order"}
        closable={false}
        forcePrimary={true}
        visible={showAddCard}
        handleSave={(data = []) => {
          const primaryPaymentMethod =
            data.find((card) => card?.isPrimary === true) ?? data?.[0];
          setShowAddCard(false);
          getData(primaryPaymentMethod?.paymentMethodId);
        }}

        // onCancel={() => setShowAddCard(false)}
      />
    </Content>
  );
}
