import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { clearAllData } from "redux/actions/locationCenter.action";

import {
  getFaclityInZone,
  getProducts,
  getAddons,
} from "services/locationCenter.services";

import { GOOGLE_MAP_KEY } from "utils/constants";
import { OperationType } from "utils/enums";
import { openNotificationWithIcon } from "utils/Notification";

const useLocationCenterNew = ({
  initialStreetAddress,
  initialOperationTypeId,
  initialFacilityId,
  intialProductId,
  addonOnly = false,
} = {}) => {
  const abortConRef = useRef();
  const dispatch = useDispatch();
  const locationCenters = useSelector((state) => state.locationCenter);

  const [error, setError] = useState();
  const [isFetchingCoords, setIsFetchingCoords] = useState(false);
  const [isFetchingAddons, setIsFetchingAddons] = useState(false);

  const [addOns, setAddons] = useState([]);

  const shouldShowError = (operationId, address) => {
    if (operationId === OperationType.Telehealth && address?.length >= 5)
      return true;
    else if (operationId === OperationType.Mobile) return true;
    return false;
  };

  useEffect(() => {
    if (initialOperationTypeId == 1) {
      // set street address
      setStreetAddress(initialStreetAddress, 1);
    }
    if (initialFacilityId !== undefined) {
      dispatch(getProducts(initialFacilityId, initialOperationTypeId, false));
    }
    if (intialProductId !== undefined) {
      setIsFetchingAddons(true);

      dispatch(
        getAddons(
          initialFacilityId,
          initialOperationTypeId,
          intialProductId,
          false
        )
      ).then((data) => {
        setAddons(data);
        setIsFetchingAddons(false);
      });
    }
  }, []);

  /**
   * use the street address to fetch the coordinates,
   * then get the inZone facility, then if facility is available
   * , get the Products in that facility and populate the dropdown
   * . Also can set local errors for invalid address or out of zone facility
   * @param  {String} streetAddress streetaddress
   * @param  {Number} operationTypeId operationTypeId
   */
  const setStreetAddress = (streetAddress, operationTypeId, onChange) => {
    if (!streetAddress) return;
    if (abortConRef.current) abortConRef.current.abort();
    abortConRef.current = new AbortController();
    const urlEncodedStreetAddress = encodeURIComponent(streetAddress);
    setIsFetchingCoords(true);
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_MAP_KEY}&fields=formatted_address&address=${
        operationTypeId === OperationType.Telehealth ? "USA " : ""
      }${urlEncodedStreetAddress}`,
      {
        signal: abortConRef?.current?.signal,
      }
    )
      .then((response) => response.json())
      .then(async (data) => {
        const lat = data.results[0]?.geometry?.location?.lat;
        const lng = data.results[0]?.geometry?.location?.lng;

        let state = "";
        let country = "US";
        let zipCode = "";
        for (let addressComponent of data.results[0]?.["address_components"]) {
          if (
            addressComponent?.["types"]?.includes("administrative_area_level_1")
          )
            state = addressComponent?.["short_name"];
          else if (addressComponent?.["types"]?.includes("country"))
            country = addressComponent?.["short_name"];
          else if (addressComponent?.["types"]?.includes("postal_code"))
            zipCode = addressComponent?.["short_name"];
        }

        if (
          lat !== undefined &&
          lng !== undefined &&
          !!state &&
          !!country &&
          !!zipCode
        ) {
          setError();
          const showError = shouldShowError(
            operationTypeId,
            urlEncodedStreetAddress
          );

          const response = await dispatch(
            getFaclityInZone(
              operationTypeId,
              {
                lat,
                lng,
              },
              true,
              showError,
              abortConRef?.current?.signal
            )
          );
          if (response?.status === "success") {
            onChange?.(response.data?.[0]?.facilityId);
            if (response?.data?.length === 0) setError("No facility found");
            else {
              setError();
            }
            // if (operationTypeId !== undefined)
            //   dispatch(
            //     getProducts(
            //       response?.data?.[0]?.facilityId,
            //       operationTypeId,
            //       true
            //     )
            //   );
          } else {
            // Can can errors
            onChange?.();
            setError("No facility found");
          }
        } else {
          setError("Please enter a valid street address");
        }
      })
      .catch((e, xhr) => {
        // message is different on IOS devices unable to keep it consistent on all platforms/devices
        // if (e.message !== "The user aborted a request.")
        //   openNotificationWithIcon(
        //     "error",
        //     "Error!",
        //     e?.response?.data?.message || "Network error has occured"
        //   );
      })
      .finally(() => {
        setIsFetchingCoords(false);
      });
  };

  /**
   * using the facilityId to fetch the Products and populate the dropdown
   * @param  {Number} facilityId facilityId
   * @param  {Number} operationTypeId operationTypeId
   * @param  {Number} [productId] optional productId
   * @param  {Function} [onChange] optional productId
   */
  const setClinic = (facilityId, operationTypeId, productId) => {
    if (facilityId !== undefined) {
      dispatch(getProducts(facilityId, operationTypeId, true));
    }
    if (productId !== undefined) {
      setIsFetchingAddons(true);
      dispatch(getAddons(facilityId, operationTypeId, productId, false)).then(
        (data) => {
          setAddons(data);
          setIsFetchingAddons(false);
        }
      );
    }
  };

  /**
   * set the product and fetch the addons
   * then set the addOns in useLocationCenter state
   * which is being returned by the hook
   * @param  {Number} facilityId facilityId
   * @param  {Number} operationTypeId operationTypeId
   * @param  {Number} productId productId
   */
  const setProduct = (productId, operationId, facilityId) => {
    setIsFetchingAddons(true);
    dispatch(getAddons(facilityId, operationId, productId, true)).then(
      (data) => {
        setIsFetchingAddons(false);
        setAddons(data);
      }
    );
  };

  /**
   * clear the redux data for services/products that have been fetched agains location/clinic
   */
  const clearLocationCenterData = () => {
    dispatch(clearAllData());
    setAddons([]);
  };

  return {
    locationCenters,
    setStreetAddress,
    setClinic,
    setProduct,
    clearLocationCenterData,
    isFetchingAddons,
    isFetchingCoords,
    error,
    addOns,
  };
};

export default useLocationCenterNew;
