import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { atcb_action, atcb_init } from "utils/addCalender";
import "add-to-calendar-button/assets/css/atcb.min.css";

const DEFAULT_OPTIONS = [
  "Apple",
  "Google",
  "iCal",
  "Microsoft365",
  "MicrosoftTeams",
  "Outlook.com",
  "Yahoo",
];

function AddToCalendar({
  startDate,
  startTime,
  endDate,
  endTime,
  name,
  description,
  options = DEFAULT_OPTIONS,
  location,
  children,
}) {
  useEffect(() => {}, []);
  const addToCalendarRef = useRef();
  return (
    <div
      style={{ display: "inline-block" }}
      ref={addToCalendarRef}
      onClick={() => {
        if (startDate) {
          const config = {
            name,
            description,
            startDate,
            startTime,
            endDate,
            endTime,
            options,
            iCalFileName: "Reminder-Event",
            trigger: "click",
            timeZone: moment?.tz?.guess?.(),
            location,
          };

          atcb_action(config, addToCalendarRef.current);
        }
      }}
    >
      {children}
    </div>
  );
}

AddToCalendar.propTypes = {};

export default AddToCalendar;
