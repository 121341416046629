import React, { useState } from "react";
import PropTypes from "prop-types";

import moment from "moment";
import { Row, Col, Space, Typography, Button, Modal } from "antd";
import { ReactComponent as CalenderIcon } from "assets/icons/calenderIco.svg";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import { cancelOrder } from "services/booking.service";
import { setLoading } from "redux/actions/loading.actions";
import { setCurrentBooking } from "redux/actions/booking.action";
import { VideoCameraOutlined } from "@ant-design/icons";
import { MEET_PROVIDER } from "utils/constants";
import { MeetingVendor } from "utils/enums";

const { Title, Text } = Typography;
const { confirm, warning, success, error } = Modal;

function AppointmentsCard({ id, type, date, durationHours, status, data , meetingVendor, timeZone}) {
  // const [showCancelModal, setShowCancelModal] = useState(false);
  const { joinUrl, productType } = data;
  const dispatch = useDispatch();

  const cancelBookingModal = {
    title: "Cancel Booking",
    content: <>Are you sure you want to cancel this appointment?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      dispatch(setLoading(true));
      dispatch(cancelOrder(id)).then((result) => {
        if (result?.status === "success") close();
        dispatch(setLoading(false));
      });
    },
  };

  return (
    <Row className="appointmentCardContainer">
      <Col span={18}>
        <Title
          level={4}
          style={{ cursor: "pointer" }}
          className="tradeGothic colorPrimary"
          onClick={async () => {
            await dispatch(setCurrentBooking(data));
            await dispatch(push("/thankyou"));
          }}
        >
          {type}{" "}
          {/* {durationHours !== undefined &&
            durationHours > 0 &&
            `(${Number(durationHours).toFixed()} hr)`} */}
        </Title>
        <Space size="small" style={{alignItems:'flex-start'}}>
          <CalenderIcon />
          <Text>
            {moment(date).format("MMMM DD, yyyy - h:mmA")} ({timeZone})
          </Text>
        </Space>
        <br />
        {joinUrl && (
          <Space
            onClick={() => {
              if (joinUrl) window.open(joinUrl, "_blank");
            }}
            style={{
              cursor: "pointer",
            }}
          >
            <VideoCameraOutlined
              style={{ color: "#486baf", fontSize: 22, paddingLeft: 4 }}
            />
            <Text
              style={{
                color: "#486baf",
                display: "inline-block",
                fontFamily: "tradeGothic",
              }}
            >
              Join{" "}
              {meetingVendor !== MeetingVendor.Zoom ? MEET_PROVIDER : "Zoom"}{" "}
              Call
            </Text>
          </Space>
        )}
      </Col>
      <Col span={6} className="flex justifyEnd">
        <Space
          direction="vertical"
          size="small"
          style={{ paddingTop: 4 }}
          align="end"
        >
          <Title level={5} className="tradeGothic" style={{ marginBottom: 0 }}>
            Status
          </Title>
          <Title
            level={5}
            className={`tradeGothic ${
              status === "Cancelled" || status === "No Show"
                ? "colorWarning"
                : "colorSuccess"
            }`}
            style={{ marginBottom: 4 }}
          >
            {status}
          </Title>
          {status === "Booked" && (
            <Button
              type="text"
              onClick={() => {
                // setShowCancelModal(!showCancelModal)
                confirm(cancelBookingModal);
              }}
            >
              Cancel
            </Button>
          )}
        </Space>
      </Col>
      {/* <Modal
        title="Modal"
        visible={showCancelModal}
        onOk={() => setShowCancelModal(!showCancelModal)}
        onCancel={() => setShowCancelModal(!showCancelModal)}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure, you want to cancel your booking?</p>
      </Modal> */}
    </Row>
  );
}

AppointmentsCard.propTypes = {};

export default AppointmentsCard;
